import { Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import {
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  useDataProvider,
} from 'react-admin';

const CreateForm = () => {
  const dataProvider = useDataProvider();
  const [positionData, setPositionData] = useState();
  const [companyData, setCompanyData] = useState();

  const positionId = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const _id = params.get('positionId');
    return _id;
  }, []);

  useEffect(() => {
    const run = async () => {
      const positionResponse = await dataProvider.getOne('positions', { id: positionId });
      setPositionData(positionResponse.data);
      const companyResponse = await dataProvider.getOne('companies', { id: positionResponse.data.companyId });
      setCompanyData(companyResponse.data);
    };
    run();
  }, []);

  const defaultValues = useMemo(() => {
    if (!positionData || !companyData) {
      return null;
    }
    const contacts = (companyData?.contacts || [])
      .filter((contact) => contact.isVerified)
      .map((contact) => ({
        ...contact,
        enabled: false,
      }));
    return {
      positionId: positionData?._id,
      text: positionData?.defaultCoverLetter,
      recipients: contacts,
      name: `Job Application - ${positionData.title}`,
    };
  }, [positionData, companyData]);
  if (!defaultValues) {
    return null;
  }
  console.log({ defaultValues, positionId });
  return (
    <TabbedForm defaultValues={defaultValues}>
      <FormTab label="General">
        <Stack spacing={2} width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextInput source="name" fullWidth />
            <BooleanInput source="enabled" />
          </Stack>
          <TextInput multiline source="text" fullWidth />
          <ArrayInput source="recipients">
            <SimpleFormIterator>
              <TextInput source="title" fullWidth />
              <TextInput source="link" fullWidth />
              <TextInput source="email" fullWidth />
              <BooleanInput source="enabled" />
            </SimpleFormIterator>
          </ArrayInput>
        </Stack>
      </FormTab>
    </TabbedForm>
  );
};

export default CreateForm;
