import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getApiUrl } from 'utils/api';
import CrawlList from 'components/resources/Crawl/List';
import CrawlShow from 'components/resources/Crawl/Show';
import CrawlCreate from 'components/resources/Crawl/Create';
import CrawlEdit from 'components/resources/Crawl/Edit';

import PositionList from 'components/resources/Position/List';
import PositionShow from 'components/resources/Position/Show';
import PositionCreate from 'components/resources/Position/Create';
import PositionEdit from 'components/resources/Position/Edit';

import SubscriptionList from 'components/resources/Subscription/List';
import SubscriptionShow from 'components/resources/Subscription/Show';
import SubscriptionCreate from 'components/resources/Subscription/Create';
import SubscriptionEdit from 'components/resources/Subscription/Edit';

import TemplateCoverLetterList from 'components/resources/TemplateCoverLetter/List';
import TemplateCoverLetterShow from 'components/resources/TemplateCoverLetter/Show';
import TemplateCoverLetterCreate from 'components/resources/TemplateCoverLetter/Create';
import TemplateCoverLetterEdit from 'components/resources/TemplateCoverLetter/Edit';

import TemplateCVList from 'components/resources/TemplateCV/List';
import TemplateCVShow from 'components/resources/TemplateCV/Show';
import TemplateCVCreate from 'components/resources/TemplateCV/Create';
import TemplateCVEdit from 'components/resources/TemplateCV/Edit';

import CompanyList from 'components/resources/Company/List';
import CompanyShow from 'components/resources/Company/Show';
import CompanyCreate from 'components/resources/Company/Create';
import CompanyEdit from 'components/resources/Company/Edit';

import CoverLetterList from 'components/resources/CoverLetter/List';
import CoverLetterShow from 'components/resources/CoverLetter/Show';
import CoverLetterCreate from 'components/resources/CoverLetter/Create';
import CoverLetterEdit from 'components/resources/CoverLetter/Edit';

import { Alert } from '@mui/material';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: CrawlList, permissions: ['view'], key: 'CrawlList' },
    { component: CrawlShow, permissions: ['view'], key: 'CrawlShow' },
    { component: CrawlCreate, permissions: ['view', 'edit'], key: 'CrawlCreate' },
    { component: CrawlEdit, permissions: ['view', 'edit'], key: 'CrawlEdit' },

    { component: PositionList, permissions: ['view'], key: 'PositionList' },
    { component: PositionShow, permissions: ['view'], key: 'PositionShow' },
    { component: PositionCreate, permissions: ['view', 'edit'], key: 'PositionCreate' },
    { component: PositionEdit, permissions: ['view', 'edit'], key: 'PositionEdit' },

    { component: SubscriptionList, permissions: ['view'], key: 'SubscriptionList' },
    { component: SubscriptionShow, permissions: ['view'], key: 'SubscriptionShow' },
    { component: SubscriptionCreate, permissions: ['view', 'edit'], key: 'SubscriptionCreate' },
    { component: SubscriptionEdit, permissions: ['view', 'edit'], key: 'SubscriptionEdit' },

    { component: TemplateCoverLetterList, permissions: ['view'], key: 'TemplateCoverLetterList' },
    { component: TemplateCoverLetterShow, permissions: ['view'], key: 'TemplateCoverLetterShow' },
    { component: TemplateCoverLetterCreate, permissions: ['view', 'edit'], key: 'TemplateCoverLetterCreate' },
    { component: TemplateCoverLetterEdit, permissions: ['view', 'edit'], key: 'TemplateCoverLetterEdit' },

    { component: TemplateCVList, permissions: ['view'], key: 'TemplateCVList' },
    { component: TemplateCVShow, permissions: ['view'], key: 'TemplateCVShow' },
    { component: TemplateCVCreate, permissions: ['view', 'edit'], key: 'TemplateCVCreate' },
    { component: TemplateCVEdit, permissions: ['view', 'edit'], key: 'TemplateCVEdit' },

    { component: CompanyList, permissions: ['view'], key: 'CompanyList' },
    { component: CompanyShow, permissions: ['view'], key: 'CompanyShow' },
    { component: CompanyCreate, permissions: ['view', 'edit'], key: 'CompanyCreate' },
    { component: CompanyEdit, permissions: ['view', 'edit'], key: 'CompanyEdit' },

    { component: CoverLetterList, permissions: ['view'], key: 'CoverLetterList' },
    { component: CoverLetterShow, permissions: ['view'], key: 'CoverLetterShow' },
    { component: CoverLetterCreate, permissions: ['view', 'edit'], key: 'CoverLetterCreate' },
    { component: CoverLetterEdit, permissions: ['view', 'edit'], key: 'CoverLetterEdit' },

  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="crawls"
        list={AvailableComponents.CrawlList}
        show={AvailableComponents.CrawlShow}
        create={AvailableComponents.CrawlCreate}
        edit={AvailableComponents.CrawlEdit}
      />
      <Resource
        name="positions"
        list={AvailableComponents.PositionList}
        show={AvailableComponents.PositionShow}
        create={AvailableComponents.PositionCreate}
        edit={AvailableComponents.PositionEdit}
      />
      <Resource
        name="subscriptions"
        list={AvailableComponents.SubscriptionList}
        show={AvailableComponents.SubscriptionShow}
        create={AvailableComponents.SubscriptionCreate}
        edit={AvailableComponents.SubscriptionEdit}
      />
      <Resource
        name="templateCoverLetters"
        list={AvailableComponents.TemplateCoverLetterList}
        show={AvailableComponents.TemplateCoverLetterShow}
        create={AvailableComponents.TemplateCoverLetterCreate}
        edit={AvailableComponents.TemplateCoverLetterEdit}
      />
      <Resource
        name="templateCVs"
        list={AvailableComponents.TemplateCVList}
        show={AvailableComponents.TemplateCVShow}
        create={AvailableComponents.TemplateCVCreate}
        edit={AvailableComponents.TemplateCVEdit}
      />
      <Resource
        name="companies"
        list={AvailableComponents.CompanyList}
        show={AvailableComponents.CompanyShow}
        create={AvailableComponents.CompanyCreate}
        edit={AvailableComponents.CompanyEdit}
      />
      <Resource
        name="coverLetters"
        list={AvailableComponents.CoverLetterList}
        show={AvailableComponents.CoverLetterShow}
        create={AvailableComponents.CoverLetterCreate}
        edit={AvailableComponents.CoverLetterEdit}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
