import React from 'react';
import {
  TextField,
  BooleanField,
  SimpleShowLayout,
  Show,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, Stack } from '@mui/material';

const ItemShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Template CV
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <BooleanField source="enabled" />
          </SimpleShowLayout>
        </Stack>
      </Card>
    </Stack>
    <Stack p={2} spacing={2}>
      <Card raised>
        <Stack p={2}>
          <TextField source="text" sx={{ whiteSpace: 'pre-line'}} />
        </Stack>
      </Card>
    </Stack>
  </Show>
);

export default ItemShow;
