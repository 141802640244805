import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  EditButton,
  ChipField,
  FunctionField,
  ReferenceField,
  TextInput,
  SelectInput,
  useCreatePath,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Link } from '@mui/material';
import {
  COMPLETENESS_TYPES,
  COVER_LETTER_TYPES,
  DATE_RANGE_TYPES,
  EASY_APPLY_TYPES,
  STATUS_TYPES,
} from 'components/resources/Position/constants';
import ButtonArea from 'components/resources/Position/ButtonArea';

const positionFilters = [
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <SelectInput label="Status" source="status" choices={STATUS_TYPES} alwaysOn resettable />,
  <SelectInput label="Date Range" source="dateRange" choices={DATE_RANGE_TYPES} alwaysOn resettable />,
  <SelectInput label="Easy Apply" source="hasEasyApply" choices={EASY_APPLY_TYPES} alwaysOn resettable />,
  <SelectInput label="Completeness" source="isComplete" choices={COMPLETENESS_TYPES} alwaysOn resettable />,
  <SelectInput label="Cover Letter" source="coverLetterStatus" choices={COVER_LETTER_TYPES} alwaysOn resettable />,
];

const ItemList = () => {
  const createPath = useCreatePath();
  return (
    <List filters={positionFilters}>
      <Datagrid>
        <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
        <ReferenceField source="subscriptionId" reference="subscriptions" link="show">
          <ChipField source="title" />
        </ReferenceField>
        <ChipField source="status" />
        <FunctionField
          label="Match score, %"
          sortBy="prediction"
          render={(record) => (record?.prediction ?? 'N/A')}
        />
        <FunctionField
          label="Title & Link"
          sortBy="title"
          render={(record) => <Link href={record?.url} target="_blank">{record?.title}</Link>}
        />
        <FunctionField
          label="Company Name"
          render={(record) => (
            <>
              {!!record.companyId && (
                <Link
                  to={createPath({ resource: 'companies', id: record.companyId, type: 'show' })}
                >
                  <TextField source="companyName" />
                </Link>
              )}
              {!record.companyId && (
                <TextField source="companyName" />
              )}
            </>
          )}
        />
        <TextField source="salary" />
        <BooleanField source="hasEasyApply" title="Easy Apply" />
        <BooleanField source="isComplete" title="Complete" />
        <FunctionField
          label=""
          render={(record) => <ButtonArea record={record} />}
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ItemList;
