import React from 'react';
import {
  Create,
} from 'react-admin';
import CreateForm from './CreateForm';

const ItemCreate = () => (
  <Create>
    <CreateForm />
  </Create>
);

export default ItemCreate;
