import { Stack } from '@mui/material';
import { STATUS_TYPES } from 'components/resources/Position/constants';
import React from 'react';

import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General">
      <Stack spacing={2} direction="row" alignItems="center">
        <SelectInput source="status" choices={STATUS_TYPES} />
        <TextInput source="companyName" />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
