import { Divider, Stack } from '@mui/material';
import React from 'react';

import {
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General">
      <Stack spacing={2} width="100%">
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextInput source="name" fullWidth />
          <BooleanInput source="enabled" />
        </Stack>
        <TextInput multiline source="text" fullWidth />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
