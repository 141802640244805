import React from 'react';
import {
  TextField,
  BooleanField,
  SimpleShowLayout,
  Show,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, Stack } from '@mui/material';

const ItemShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Item
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="title" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="url" label="URL" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <BooleanField source="enabled" />
          </SimpleShowLayout>
        </Stack>
      </Card>
    </Stack>
  </Show>
);

export default ItemShow;
