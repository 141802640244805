export const STATUS_TYPES = [
  { name: 'New', id: 'new' },
  { name: 'Not Interested', id: 'not_interested' },
  { name: 'Interested', id: 'interested' },
  { name: 'Applied', id: 'applied' },
  { name: 'In Progress', id: 'progress' },
  { name: 'Accepted', id: 'accepted' },
  { name: 'Rejected', id: 'rejected' },
  { name: 'Abandoned', id: 'abandoned' },
];

export const EASY_APPLY_TYPES = [
  { name: 'Easy apply', id: true },
  { name: 'Without Easy Apply', id: false },
];

export const COMPLETENESS_TYPES = [
  { name: 'Complete', id: true },
  { name: 'Not Complete ', id: false },
];

export const DATE_RANGE_TYPES = [
  { name: 'Today', id: 'today' },
  { name: 'Yesterday', id: 'yesterday' },
  { name: 'Within 2 days', id: 'within_2_days' },
  { name: 'Within 3 days', id: 'within_3_days' },
  { name: 'Within 4 days', id: 'within_4_days' },
  { name: 'Until today', id: 'until_today' },
];
export const COVER_LETTER_TYPES = [
  { name: 'Present', id: 'present' },
  { name: 'Absent', id: 'absent' },
];
