/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { IconButton, Link, Stack, Tooltip } from '@mui/material';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GradingIcon from '@mui/icons-material/Grading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ApprovalIcon from '@mui/icons-material/Approval';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RedoIcon from '@mui/icons-material/Redo';
import LinkIcon from '@mui/icons-material/Link';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import WorkIcon from '@mui/icons-material/Work';

import { Link as ReactAdminLink, useCreatePath } from 'react-admin';

import { updateStatus } from 'utils/api';
import { Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

const copyAndToast = (text, toastText) => {
  copy(text);
  toast(toastText, { type: 'info', theme: 'dark', position: 'bottom-center', hideProgressBar: true });
};

const ButtonArea = ({ record }) => {
  const createPath = useCreatePath();
  const { status: originalStatus, companyId, defaultCoverLetter } = record || {};
  const [status, setStatus] = useState(originalStatus);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status !== originalStatus) {
      setLoading(true);
      updateStatus(record.id, status).then(() => {
        setLoading(false);
      });
    }
  }, [status, originalStatus, record.id]);

  if (loading) {
    return <Spin />;
  }

  return (
    <Stack direction="row" justifyContent="end">
      {status !== 'interested' && !!record.companyId && (
        <Stack>
          <Tooltip title="View Company" placement="left">
            <ReactAdminLink
              to={createPath({ resource: 'companies', id: companyId, type: 'show' })}
            >
              <IconButton><WorkIcon /></IconButton>
            </ReactAdminLink>
          </Tooltip>
        </Stack>
      )}
      {status === 'new' && (
        <Stack>
          <Tooltip title="Interested" placement="top">
            <IconButton onClick={() => setStatus('interested')}><ThumbUpIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Not Interested">
            <IconButton onClick={() => setStatus('not_interested')}><ThumbDownIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {status === 'interested' && (
        <Stack>
          <Tooltip title="Apply..." placement="left">
            <ReactAdminLink
              to={createPath({ resource: 'companies', id: companyId, type: 'show' })}
            >
              <IconButton><SendIcon /></IconButton>
            </ReactAdminLink>
          </Tooltip>
          <Tooltip title="Already Applied" placement="left">
            <IconButton onClick={() => setStatus('applied')}><GradingIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {status === 'applied' && (
        <Stack>
          <Tooltip title="Wow, response!" placement="left">
            <IconButton onClick={() => setStatus('progress')}><CheckCircleIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Abandoned" placement="left">
            <IconButton onClick={() => setStatus('abandoned')}><DeleteIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {status === 'progress' && (
        <Stack>
          <Tooltip title="Approved" placement="left">
            <IconButton onClick={() => setStatus('approved')}><ApprovalIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Rejected" placement="left">
            <IconButton onClick={() => setStatus('rejected')}><DoDisturbIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {(status === 'abandoned' || status === 'rejected' || status === 'approved') && (
        <Stack>
          <Tooltip title="To new again" placement="left">
            <IconButton onClick={() => setStatus('new')}><RedoIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      <Stack justifyContent="center">
        <Tooltip title="OPEN" placement="right">
          <Link href={record.url} target="_blank">
            <IconButton><LinkIcon /></IconButton>
          </Link>
        </Tooltip>
        {defaultCoverLetter && (
          <Tooltip title="Copy Cover Letter" placement="bottom">
            <IconButton
              onClick={() => copyAndToast(defaultCoverLetter, 'Cover Letter Copied To Clipboard')}
            >
              <CopyAllIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default ButtonArea;
