import React from 'react';
import {
  TextField,
  BooleanField,
  SimpleShowLayout,
  Show,
  RichTextField,
  FunctionField,
  UrlField,
  ReferenceField,
  ChipField,
  WrapperField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, Stack } from '@mui/material';

const ItemShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Cover Letter
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <ReferenceField source="positionId" label="Company Name" reference="positions" link={false}>
              <TextField source="companyName" />
            </ReferenceField>
          </SimpleShowLayout>
          <SimpleShowLayout>
            <BooleanField source="inviteIsShort" />
          </SimpleShowLayout>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <ReferenceField source="positionId" reference="positions" link={false}>
              <UrlField source="url" target="_blank" />
            </ReferenceField>
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="recipient.title" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <UrlField source="recipient.link" target="_blank" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="recipient.email" />
          </SimpleShowLayout>
        </Stack>
      </Card>
    </Stack>
    <Stack p={2} spacing={2}>
      <FunctionField
        render={(record) => record.shortText && (
          <Card raised>
            <Stack p={2}>
              <TextField source="shortText" sx={{ whiteSpace: 'pre-line'}} />
            </Stack>
          </Card>
        )}
      />
      <Card raised>
        <Stack p={2}>
          <TextField source="text" sx={{ whiteSpace: 'pre-line'}} />
        </Stack>
      </Card>
    </Stack>
  </Show>
);

export default ItemShow;
