import React from 'react';

import {
  List,
  Datagrid,
  ShowButton,
  EditButton,
  ChipField,
  UrlField,
  ReferenceField,
  NumberField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ItemList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <DateFieldUnixtime source="timestampFinished" label="Finished at" showTime />
      <ChipField source="status" />
      <UrlField source="url" target="_blank" />
      <NumberField source="newItems" />
      <ReferenceField source="subscriptionId" reference="subscriptions" link="show">
        <ChipField source="title" />
      </ReferenceField>
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ItemList;
