import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  EditButton,
  UrlField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ItemList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="title" />
      <UrlField source="url" label="URL" target="_blank" />
      <BooleanField source="enabled" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ItemList;
