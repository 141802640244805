import { Stack } from '@mui/material';
import React from 'react';

import {
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="title" />
        <TextInput source="url" />
        <BooleanInput source="enabled" />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
