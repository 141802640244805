/* eslint-disable i18next/no-literal-string */
import axios from 'axios';

const authFrontendUrl = window?.config?.urlAuth || process.env.REACT_APP_URL_AUTH;
const authRedirectUrl = `${authFrontendUrl}/login`;

export const TOKEN_KEY = 'token';

const createStandardAxiosInstanceWithAuth = ({
  url,
  redirectUrl,
}) => {
  const axiosInstance = axios.create({
    baseURL: url,
    timeout: 30000,
  });

  axiosInstance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
    return config;
  });
  axiosInstance.interceptors.response.use(null, (error) => {
    if (error?.response?.status === 401) {
      const redirectUrlWithCallback = `${redirectUrl}?callback_url=${window.location.href}`;
      window.location.href = redirectUrlWithCallback;
    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

export const getApiUrl = () => {
  const defaultApiUrl = `${process.env.REACT_APP_API_URL}` || '';
  const { apiUrl = defaultApiUrl } = window.config || {};
  return `${apiUrl}`;
};
export const getAuthApiUrl = () => {
  const defaultApiUrl = process.env.REACT_APP_API_URL_AUTH || '';
  const { apiUrlAuth = defaultApiUrl } = window.config || {};
  return apiUrlAuth;
};

export const axiosApiInstance = createStandardAxiosInstanceWithAuth({
  url: getApiUrl(),
  redirectUrl: authRedirectUrl,
});

export const axiosAuthInstance = createStandardAxiosInstanceWithAuth({
  url: getAuthApiUrl(),
  redirectUrl: authRedirectUrl,
});

export const getMe = async () => {
  const { data } = await axiosAuthInstance.get('/api/auth/me');
  return data;
};

export const updateStatus = async (id, newStatus) => {
  const { data } = await axiosApiInstance.post(`/positions/${id}/status`, null, { params: { newStatus } });
  return data;
};

export const openProfile = async (id, url, email) => {
  const { data } = await axiosApiInstance.post(`/companies/${id}/email`, { url, email });
  return data;
};

export const refreshContacts = async (id) => {
  const { data } = await axiosApiInstance.post(`/companies/${id}/refresh-contacts`);
  return data;
};
