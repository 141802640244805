import { Divider, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import {
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  useDataProvider,
  SelectInput,
} from 'react-admin';
import { STATUS_TYPES } from './constants';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General">
      <Stack spacing={2} width="100%">
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextInput source="name" fullWidth />
          <SelectInput source="status" choices={STATUS_TYPES} />
        </Stack>
        <TextInput source="recipient.title" fullWidth />
        <TextInput source="recipient.link" fullWidth />
        <TextInput source="recipient.email" fullWidth />
        <TextInput multiline source="shortText" fullWidth />
        <TextInput multiline source="text" fullWidth />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
