import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  EditButton,
  ArrayField,
  ChipField,
  FunctionField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ItemList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <FunctionField label="Company Name" sortBy="companyNames" render={(record) => record?.companyNames?.join(', ')} />
      <BooleanField source="enabled" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ItemList;
