/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Link, Stack, Tooltip } from '@mui/material';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GradingIcon from '@mui/icons-material/Grading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ApprovalIcon from '@mui/icons-material/Approval';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RedoIcon from '@mui/icons-material/Redo';
import LinkIcon from '@mui/icons-material/Link';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { FunctionField, Link as ReactAdminLink, ReferenceField, useCreatePath, useDataProvider } from 'react-admin';

import { Spin } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

const copyAndToast = (text, toastText) => {
  copy(text);
  toast(toastText, { type: 'info', theme: 'dark', position: 'bottom-center', hideProgressBar: true });
};

const ButtonArea = ({ record }) => {
  const createPath = useCreatePath();
  const dataProvider = useDataProvider();
  const { name, status: originalStatus, text, shortText, recipient } = record || {};
  const { link, email } = recipient || {};
  const [status, setStatus] = useState(originalStatus);
  const [loading, setLoading] = useState(false);

  const updateStatus = useCallback(async (id, newStatus) => {
    await dataProvider.update('coverLetters', {
      id,
      data: { ...record, status: newStatus },
      previousData: record,
    });
  }, [record]);

  useEffect(() => {
    if (status !== originalStatus) {
      setLoading(true);
      updateStatus(record.id, status).then(() => {
        setLoading(false);
      });
    }
  }, [status, record]);

  if (loading) {
    return <Spin />;
  }

  return (
    <Stack direction="row" justifyContent="end">
      {(!status || status === 'new') && (
        <Stack>
          <Tooltip title="Sent" placement="left">
            <IconButton onClick={() => setStatus('sent')}><SendIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Abandoned" placement="left">
            <IconButton onClick={() => setStatus('abandoned')}><DeleteIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {status === 'sent' && (
        <Stack>
          <Tooltip title="Wow, response!" placement="left">
            <IconButton onClick={() => setStatus('progress')}><CheckCircleIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Abandoned" placement="left">
            <IconButton onClick={() => setStatus('abandoned')}><DeleteIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {status === 'progress' && (
        <Stack>
          <Tooltip title="Approved" placement="left">
            <IconButton onClick={() => setStatus('approved')}><ApprovalIcon /></IconButton>
          </Tooltip>
          <Tooltip title="Rejected" placement="left">
            <IconButton onClick={() => setStatus('rejected')}><DoDisturbIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      {(status === 'abandoned' || status === 'rejected' || status === 'approved') && (
        <Stack>
          <Tooltip title="To new again" placement="left">
            <IconButton onClick={() => setStatus('new')}><RedoIcon /></IconButton>
          </Tooltip>
        </Stack>
      )}
      <Stack justifyContent="center">
        <Tooltip title="Position Link" placement="right">
          <ReferenceField source="positionId" reference="positions" link={false}>
            <FunctionField
              render={(positionRecord) => (
                <Link href={positionRecord.url} target="_blank">
                  <IconButton><LinkIcon /></IconButton>
                </Link>
              )}
            />
          </ReferenceField>
        </Tooltip>
        {link && (
          <Tooltip title="Profile" placement="right">
            <Link href={link} target="_blank">
              <IconButton><LinkedInIcon /></IconButton>
            </Link>
          </Tooltip>
        )}
      </Stack>
      <Stack justifyContent="center">
        {text && (
          <Tooltip title="Copy Cover Letter" placement="right">
            <IconButton
              onClick={() => copyAndToast(text, 'Cover Letter Copied To Clipboard')}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
        {shortText && (
          <Tooltip title="Copy Short Cover Letter" placement="right">
            <IconButton
              onClick={() => copyAndToast(shortText, 'Short Cover Letter Copied To Clipboard')}
            >
              <CopyAllIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack justifyContent="center">
        {(name && text && email) && (
          <Tooltip title="Send email now" placement="right">
            <Link href={`mailto:${email}?subject=${name}&body=${text.replaceAll('\n', '<br />\n%0D%0A')}`} target="_blank">
              <IconButton><ForwardToInboxIcon /></IconButton>
            </Link>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default ButtonArea;
