import React, { useCallback, useState } from 'react';
import {
  TextField,
  BooleanField,
  SimpleShowLayout,
  Show,
  FunctionField,
  ArrayField,
  SingleFieldList,
  Datagrid,
  UrlField,
  EmailField,
  ReferenceManyField,
  WrapperField,
  useShowContext,
  useDataProvider,
  useCreatePath,
  Link,
  ChipField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, IconButton, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MagicIcon from '@mui/icons-material/AutoFixHigh';
import { openProfile, refreshContacts } from 'utils/api';
import { Refresh } from '@mui/icons-material';
import { Spin } from 'antd';

const LoadingWrapper = ({ onClick, children }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = useCallback(async (...args) => {
    try {
      setLoading(true);
      await onClick(...args);
    } finally {
      setLoading(false);
    }
  }, [onClick]);

  if (isLoading) {
    return <Spin />;
  }

  return children(handleClick);
};

const ItemShowLayout = () => {
  const createPath = useCreatePath();
  const { record: company, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const handleRemoveURL = useCallback((companyURL) => async () => {
    if (!window.confirm(`Removing URL ${companyURL}?`)) {
      return;
    }
    await dataProvider.update('companies', {
      id: company.id,
      data: {
        ...company,
        urls: (company.urls || []).filter((url) => url !== companyURL),
      },
      previousData: company,
    });
    refetch();
  }, [company]);
  const handleRemoveContact = useCallback((contactURL) => async () => {
    if (!window.confirm(`Removing contact ${contactURL}?`)) {
      return;
    }

    await dataProvider.update('companies', {
      id: company.id,
      data: {
        ...company,
        contacts: (company.contacts || []).filter((contact) => contact.link !== contactURL),
      },
      previousData: company,
    });
    refetch();
  }, [company]);
  const handleToggleVerifyContact = useCallback((contactURL) => async () => {
    await dataProvider.update('companies', {
      id: company.id,
      data: {
        ...company,
        contacts: (company.contacts || []).map((contact) => {
          if (contact.link === contactURL) {
            return {
              ...contact,
              isVerified: !contact.isVerified,
            };
          }
          return contact;
        }),
      },
      previousData: company,
    });
    refetch();
  }, [company]);
  const handleOpenContactEmail = useCallback((contactURL, email) => async () => {
    await openProfile(company.id, contactURL, email);
    refetch();
  }, [company]);
  const handleRefreshContacts = useCallback(async () => {
    await refreshContacts(company.id);
    refetch();
  }, [company]);
  return (
    <>
      <Stack p={2} spacing={2}>
        <Card raised>
          <CardHeader>
            Company
          </CardHeader>
          <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
              <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <FunctionField label="Company Name" render={(record) => record?.companyNames?.join(', ')} />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <BooleanField source="enabled" />
            </SimpleShowLayout>
          </Stack>
        </Card>
      </Stack>
      <Stack p={2} spacing={2}>
        <Card raised>
          <Stack direction="row">
            <Stack p={2} width="30%">
              <FunctionField render={(record) => <h1>{record.companyNames?.join(', ')}</h1>} />
              <Stack maxHeight="70vh" overflow="auto" p={2}>
                <ArrayField source="descriptions" fullWidth>
                  <SingleFieldList linkType={false}>
                    <TextField source="content" sx={{ whiteSpace: 'pre-line'}} />
                  </SingleFieldList>
                </ArrayField>
              </Stack>
            </Stack>
            <Stack p={2} spacing={1} width="70%">
              <h2>URLS</h2>
              <FunctionField
                render={(record) => {
                  const urls = (record.urls || []).map((url) => ({ url }));
                  return (
                    <ArrayField fullWidth record={{ ...record, urls }} source="urls">
                      <Datagrid linkType={false}>
                        <UrlField source="url" target="_blank" />
                        <FunctionField
                          label="Actions"
                          render={(recordURL) => (
                            <LoadingWrapper onClick={handleRemoveURL(recordURL.url)}>
                              {(onClick) => (
                                <IconButton onClick={onClick}><RemoveIcon /></IconButton>
                              )}
                            </LoadingWrapper>
                          )}
                        />
                      </Datagrid>
                    </ArrayField>
                  );
                }}
              />
              <h2>
                Contacts
                <LoadingWrapper onClick={handleRefreshContacts}>
                  {(onClick) => (
                    <IconButton onClick={onClick}><Refresh /></IconButton>
                  )}
                </LoadingWrapper>
              </h2>
              <ArrayField source="contacts" fullWidth>
                <Datagrid linkType={false}>
                  <TextField source="title" />
                  <UrlField source="link" target="_blank" />
                  <EmailField source="email" />
                  <FunctionField
                    label="Email Status"
                    render={(record) => (
                      record?.emailStatus && <ChipField source="emailStatus" record={record} />
                    )}
                  />
                  <BooleanField source="isVerified" />
                  <FunctionField
                    label="Actions"
                    render={(recordURL) => (
                      <>
                        <LoadingWrapper onClick={handleOpenContactEmail(recordURL.link || '', recordURL.email || '')}>
                          {(onClick) => (
                            <IconButton onClick={onClick}><MailIcon /></IconButton>
                          )}
                        </LoadingWrapper>
                        {recordURL.link && (
                          <LoadingWrapper onClick={handleToggleVerifyContact(recordURL.link)}>
                            {(onClick) => (
                              <IconButton onClick={onClick}><CheckCircleIcon /></IconButton>
                            )}
                          </LoadingWrapper>
                        )}
                        <LoadingWrapper onClick={handleRemoveContact(recordURL.link)}>
                          {(onClick) => (
                            <IconButton onClick={onClick}><RemoveIcon /></IconButton>
                          )}
                        </LoadingWrapper>
                      </>
                    )}
                  />
                </Datagrid>
              </ArrayField>
              <h2>Positions</h2>
              <ReferenceManyField label="Positions" reference="positions" target="companyId">
                <Datagrid linkType={false}>
                  <TextField source="title" />
                  <UrlField source="url" target="_blank" />
                  <BooleanField source="isComplete" />
                  <FunctionField
                    label="Magic Apply"
                    render={(record) => (
                      <Link
                        to={`${createPath({ resource: 'coverLetters', type: 'create' })}?positionId=${record.id}`}
                      >
                        <IconButton><MagicIcon /></IconButton>
                      </Link>
                    )}
                  />
                </Datagrid>
              </ReferenceManyField>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </>
  );
};

const ItemShow = () => (
  <Show>
    <ItemShowLayout />
  </Show>
);

export default ItemShow;
