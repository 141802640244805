import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  EditButton,
  ReferenceField,
  FunctionField,
  Link,
  useCreatePath,
  EmailField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import ButtonArea from './ButtonArea';

const ItemList = () => {
  const createPath = useCreatePath();
  return (
    <List>
      <Datagrid>
        <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
        <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
        <TextField source="name" />
        <ReferenceField source="positionId" label="Company Name" reference="positions" link={false}>
          <FunctionField
            render={(record) => (
              <Link
                to={createPath({ resource: 'companies', id: record.companyId, type: 'show' })}
              >
                <TextField source="companyName" />
              </Link>
            )}
          />
        </ReferenceField>
        <FunctionField
          label="Profile"
          render={(record) => record?.recipient?.link && (
            <a
              href={record?.recipient?.link}
              target="_blank"
              rel="noreferrer"
            >
              <TextField
                source="title"
                record={{
                  title: (
                    record?.recipient?.profile?.full_name
                    || record?.recipient?.title
                  ),
                }}
              />
            </a>
          )}
        />
        <EmailField source="recipient.email" />
        <ReferenceField source="positionId" label="Position" reference="positions" link="show">
          <TextField source="title" />
        </ReferenceField>
        <FunctionField
          label=""
          render={(record) => <ButtonArea record={record} />}
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ItemList;
