import React from 'react';
import {
  TextField,
  BooleanField,
  SimpleShowLayout,
  Show,
  UrlField,
  FunctionField,
  Link,
  useCreatePath,
  ChipField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, Stack } from '@mui/material';

const ItemShow = () => {
  const createPath = useCreatePath();
  return (
    <Show>
      <Stack p={2} spacing={2}>
        <Card raised>
          <CardHeader>
            Item
          </CardHeader>
          <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
              <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
              <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source="title" />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <FunctionField
                label="Company Name"
                render={(record) => (
                  <>
                    {!!record.companyId && (
                      <Link
                        to={createPath({ resource: 'companies', id: record.companyId, type: 'show' })}
                      >
                        <TextField source="companyName" />
                      </Link>
                    )}
                    {!record.companyId && (
                      <TextField source="companyName" />
                    )}
                  </>
                )}
              />
            </SimpleShowLayout>
            <Stack>
              <h2>Statuses</h2>
              <Stack direction="row" alignItems="end">
                <SimpleShowLayout>
                  <ChipField source="fetchStatus" label="Position" />
                </SimpleShowLayout>
                <SimpleShowLayout>
                  <ChipField source="fetchCompanyStatus" label="Company" />
                </SimpleShowLayout>
                <SimpleShowLayout>
                  <ChipField source="coverLetterGenStatus" label="CoverLetter" />
                </SimpleShowLayout>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
              <UrlField source="url" target="_blank" />
            </SimpleShowLayout>
          </Stack>
        </Card>
      </Stack>
      <Stack p={2} spacing={2} direction="row">
        <Stack width="50%">
          <FunctionField
            label="Description"
            render={(record) => record.description && (
              <Card raised>
                <Stack p={2}>
                  <TextField source="description" sx={{ whiteSpace: 'pre-line'}} />
                </Stack>
              </Card>
            )}
          />
        </Stack>
        <Stack width="50%">
          <FunctionField
            label="Default Cover Letter"
            render={(record) => record.defaultCoverLetter && (
              <Card raised>
                <Stack p={2}>
                  <TextField source="defaultCoverLetter" sx={{ whiteSpace: 'pre-line'}} />
                </Stack>
              </Card>
            )}
          />
        </Stack>
      </Stack>
    </Show>
  );
};

export default ItemShow;
