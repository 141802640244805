import React from 'react';
import {
  DateField,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

const DateFieldUnixtime = (props) => {
  const record = useRecordContext();
  const recordWithTimestampAsInteger = {
    [props.source]: record[props.source] ? new Date(parseInt(record[props.source], 10) * 1000) : null,
  };
  return <DateField {...props} record={recordWithTimestampAsInteger} />;
};

DateFieldUnixtime.propTypes = {
  source: PropTypes.string.isRequired,
};

export default DateFieldUnixtime;
